import { useContext, useMemo } from 'react';
import MenuItem from './MenuItem';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import BasicPopup from '../../../Common/Popups/BasicPopup/BasicPopup';
import styles from './Menu.module.css';

interface Props {
    incrementKey?: () => void,
    onClose?: () => void,
    noOverlay?: boolean,
    fadeIn?: boolean,
    open: boolean
}

export default function Menu({ incrementKey, onClose, noOverlay, open }: Props) {

    const { isGlobalTraining } = useContext(TrainingContext);
    const { parms } = useContext(ParmContext);
    const { clerkSettings: { completedTrainings: { bp, ageVerif, mo, mtu, giftCards, support, netspend } } } = useContext(SettingsContext);

    const closeable = !!onClose;

    const isMEMO = ((parms?.clerkInfo.memoId ?? 0) > 0);

    const isBillPayDisabled = (isGlobalTraining && bp)
        || (!parms?.bpActive || !parms.parameters.billPayActive);

    const isAgeVerifDisabled = (isGlobalTraining && ageVerif)
        || !parms?.parameters.ageVerifActive;

    const isPrePaidDebitDisabled = true;

    const isMoneyOrderDisabled = (isGlobalTraining && mo)
        || !("serial" in navigator)
        || !(parms?.moActive);

    const isMTUDisabled = (isGlobalTraining && mtu)
        || !parms?.parameters.prePayActive;

    const isGiftCardDisabled = (isGlobalTraining && giftCards)
        || !parms?.parameters.giftCardActive;

    const isHelpDisabled = isGlobalTraining && support;

    const isNetSpendDisabled = (isGlobalTraining && netspend)
        || !parms?.parameters.netspendActive;

    const buttonData = useMemo(() => [{
        path: '/BillPay',
        disabled: isBillPayDisabled,
        text: 'BILL PAY'
    }, {
        path: '/MoneyOrder',
        disabled: isMoneyOrderDisabled,
        text: 'MONEY ORDER',
        hidden: isMEMO && isMoneyOrderDisabled
    }, {
        path: '/MobileTopUp',
        disabled: isMTUDisabled,
        text: ['MOBILE TOP-UP', 'PREPAID MINUTES']
    }, {
        path: '/NetSpend',
        disabled: isNetSpendDisabled,
        text: 'NETSPEND',
        isNew: true,
        isSoon: true,
        hidden: isMEMO && isNetSpendDisabled
    }, {
        path: '/DigitalGiftCards',
        disabled: isGiftCardDisabled,
        text: 'GIFT CARD',
        hidden: isMEMO && isGiftCardDisabled
    }, {
        path: '/AgeVerification',
        disabled: isAgeVerifDisabled,
        text: 'AGE VERIFY'
    }, {
        path: '/PrePaidDebit',
        disabled: isPrePaidDebitDisabled,
        text: 'PRE-PAID DEBIT CARD',
        isNew: true,
        hidden: true
    }, {
        path: '/Support',
        disabled: isHelpDisabled,
        text: 'HELP'
    }].filter(({ hidden }) => !hidden)
        .sort((a, b) => {
            if (a.disabled && !b.disabled) {
                return 1;
            } else if (!a.disabled && b.disabled) {
                return -1;
            }
            return 0;
        }), [isAgeVerifDisabled, isBillPayDisabled, isGiftCardDisabled, isHelpDisabled, isMEMO, isMTUDisabled, isMoneyOrderDisabled, isNetSpendDisabled, isPrePaidDebitDisabled]);

    return (
        <BasicPopup
            className={`${styles.menuPopup} ${closeable ? styles.closeable : ''}`}
            onClose={onClose}
            noOverlay={noOverlay}
            open={open}
            fadeIn>
            <div className={styles.title}>PLEASE SELECT A PRODUCT BELOW</div>
            <div className={styles.menu}>
                {buttonData.map(({ path, disabled, text, isNew, isSoon }) =>
                    <MenuItem
                        path={path}
                        disabled={disabled}
                        incrementKey={incrementKey}
                        key={path}
                        onClose={onClose}
                        isNew={isNew}
                        isSoon={isSoon}>{
                            Array.isArray(text) ?
                                text.map((item) =>
                                    <div key={item} className={styles.menuItemSection}>{item}</div>)
                                : text
                        }</MenuItem>
                )}
            </div>
        </BasicPopup>
    );
}