import { useContext, useCallback } from 'react';
import packageJson from '../../../package.json';
import { AuthContext } from '../HigherOrder/AuthController/AuthController';
import { APIContext } from '../HigherOrder/APIController/APIController';
import { SystemContext } from '../HigherOrder/SystemConroller/SystemController';
import type { Customer } from './BillPay';
import { softwareName } from 'utilities/Environment';
import { useMTUPrinter } from 'components/Common/Receipts/MTUPrinter';
import { js2xml, xml2js } from 'utilities/xml';
import { FetchError } from 'utilities/network';

export interface RawOperatorPreview {
    billerId: string,
    id: string,
    name: string
}

export interface OperatorPreview {
    billerId: number,
    operatorId: string,
    operator: string
}

export interface Product {
    localInfoAmount: string,
    localInfoCurrency: string,
    retailPrice: number,
    wholesalePrice: number
}

export interface Promotion {
    description: string,
    endDate: string,
    startDate: string,
    terms: string,
    title: string,
}

export interface FullOperator extends OperatorPreview {
    authenticationKey: string,
    products: Product[],
    promotions?: Promotion[],
    discount: number,
    fee: number,
    isDefault: boolean
}

export interface PaymentResponseBody {
    response_code: string,
    response_message: string,
    rcptdt: string,
    rcpttm: string,
    detrac: string,
    decust: string,
    debilr: string,
    deagnt: string,
    deauth: string,
    desurc: string,
    deamtf: string,
    receipt_text: string
}

export interface PaymentResponse {
    prepaid: {
        pm_payment: PaymentResponseBody,
        tranPathId: string,
    }
}

const instanceOfPaymentResponse = (xml: unknown): xml is PaymentResponse =>
    (xml as PaymentResponse)?.prepaid?.pm_payment?.response_code !== undefined

export interface PaymentHistory {
    date: string,
    time: string,
    trace: string,
    authCode: string,
    cashAmt: number,
    localAmt: string,
    localCurrency: string,
    totalAmt: number,
    totalFee: number,
    accountNum: string,
    receiptText: string,
    providerName: string,
    productName: string,
    customerName: string,
    customerPhone: string,
    customerZip: string,
    customerEmail: string
}

export interface OperatorProps {
    phone: string,
    countryCode: string,
    billerId?: number
}

export interface TopupProps {
    phone: string,
    countryCode: string
}

export class MTUError extends Error {
    response;

    constructor(response: PaymentResponse) {
        super(response.prepaid.pm_payment.response_message || 'An unexpected error has occurred');

        this.response = response;
    }
}

const parseAndSortOperators = (operators: RawOperatorPreview[]) =>
    operators.map(({ id, name, billerId }) => ({
        operatorId: id,
        operator: name,
        billerId: Number(billerId)
    })).sort(({ operator: a }, { operator: b }) =>
        a.localeCompare(b));

const getAmount = (product: Product) =>
    product.localInfoCurrency === 'USD' ? Math.round(Number(product.localInfoAmount) * 100)
        : product.retailPrice;

const getFee = (product: Product) =>
    (product.retailPrice - getAmount(product)) / 100;

const getTotal = (product: Product) =>
    product.retailPrice / 100;

const getProductName = (product: Product) =>
    product.localInfoCurrency === 'USD' ? '$' + product.localInfoAmount
        : product.localInfoAmount + ' ' + product.localInfoCurrency;

export function useMTU(service: 'GIFTCARD' | 'TOPUP') {

    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);
    const { system: { serial } } = useContext(SystemContext);
    const { printLastPayment } = useMTUPrinter(service);

    const getMTUOperatorsRequest = useCallback(async () => {
        if (auth) {
            const response = await failoverFetch('/Operators?' + new URLSearchParams({
                authToken: auth.authToken,
                phone: "LIST"
            }));
            const data = JSON.parse(response) as RawOperatorPreview[];
            return parseAndSortOperators(data);
        }
        return [];
    }, [auth, failoverFetch]);

    const getGiftOperatorsRequest = useCallback(async () => {
        if (auth) {
            const response = await failoverFetch('/Operators?' + new URLSearchParams({
                authToken: auth.authToken,
                phone: "GIFTLIST"
            }));
            const data = JSON.parse(response) as RawOperatorPreview[];
            return parseAndSortOperators(data);
        }
        return [];
    }, [auth, failoverFetch]);

    const getOperatorsRequest = service === 'GIFTCARD' ? getGiftOperatorsRequest : getMTUOperatorsRequest;

    const getOperatorRequest = useCallback(async ({ phone, countryCode, billerId }: OperatorProps) => {
        if (auth) {
            const searchParams = new URLSearchParams({
                authToken: auth.authToken,
                phone: countryCode + phone
            });

            if (billerId) {
                searchParams.set('billerId', billerId.toString());
            }

            const response = await failoverFetch('/Operators?' + searchParams);
            const data = JSON.parse(response) as FullOperator[];
            data.sort((a, b) => {
                if (a.isDefault && !b.isDefault) {
                    return -1;
                } else if (!a.isDefault && b.isDefault) {
                    return 1;
                } else if (b.discount !== a.discount) {
                    return b.discount - a.discount;
                } else if (a.fee !== b.fee) {
                    return a.fee - b.fee;
                } else {
                    return a.billerId - b.billerId;
                }
            })
            for (const { products } of data) {
                products.sort(({ retailPrice: a }, { retailPrice: b }) => a - b);
            }
            return data;
        }
        return [];
    }, [auth, failoverFetch]);

    const topupRequest = useCallback(async ({ phone, countryCode }: TopupProps, operator: FullOperator, product: Product, customer?: Pick<Customer, 'phone' | 'name' | 'id'>) => {
        if (auth) {
            const xml = js2xml({
                prepaid: {
                    pm_payment: {
                        deagnt: auth.agentId,
                        device_sn: serial,
                        desv: softwareName,
                        depv: packageJson.version,
                        declrk: auth.clerkId,
                        debilr: operator.billerId.toString(),
                        deamtt: (product.retailPrice / 100).toFixed(2),
                        deocr: countryCode + phone.replace(/\D/g, ''),
                        authToken: auth.authToken
                    },
                    ...(customer ? {
                        consumerInfo: {
                            customerId: customer.id,
                            name: customer.name,
                            phone: customer.phone
                        }
                    } : {}),
                    tranPathId: '0'
                }
            });

            try {
                const response = await failoverFetch('/Operators', {
                    method: 'POST',
                    body: xml,
                    headers: { 'Content-Type': 'text/xml' }
                });
                const paymentResponse = xml2js<PaymentResponse>(response);
                if (Number(paymentResponse.prepaid.pm_payment.response_code) !== 0) {
                    throw new MTUError(paymentResponse);
                }
                return paymentResponse.prepaid.pm_payment;
            } catch (err) {
                if (err instanceof FetchError) {
                    const response = await err.response.text();
                    const paymentResponse = xml2js<PaymentResponse>(response);
                    if (instanceOfPaymentResponse(paymentResponse)) {
                        throw new MTUError(paymentResponse);
                    }
                }
                throw err;
            }
        }
        throw new Error('Auth not found!');
    }, [auth, failoverFetch, serial]);

    const printReceipt = useCallback(async (paymentResponse: PaymentResponseBody, operator: FullOperator, product: Product, customerName?: string) => {
        try {
            await printLastPayment({
                date: paymentResponse.rcptdt,
                time: paymentResponse.rcpttm,
                trace: paymentResponse.detrac,
                authCode: paymentResponse.deauth,
                cashAmt: getAmount(product),
                localAmt: product.localInfoAmount,
                localCurrency: product.localInfoCurrency,
                totalAmt: getTotal(product),
                totalFee: getFee(product),
                accountNum: paymentResponse.decust,
                receiptText: paymentResponse.receipt_text,
                providerName: operator.operator,
                productName: getProductName(product),
                customerName: customerName ?? '',
                customerPhone: paymentResponse.decust,
                customerZip: '',
                customerEmail: ''
            });
        } catch (err) {
            console.error(err);
        }
    }, [printLastPayment])

    return {
        getOperatorsRequest,
        getOperatorRequest,
        topupRequest,
        printReceipt
    };
}